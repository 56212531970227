import { ChangeLanguageInterface } from '@interface/UserInterface';
import { AxiosError, AxiosResponse } from 'axios';

import { axiosAuthInstance } from './axios.authConfig';

export const postChangeLanguage = async (
  body: ChangeLanguageInterface
): Promise<BaseResponseInterface> => {
  return axiosAuthInstance
    .post('/users/language', body)
    .then((res: AxiosResponse<BaseResponseInterface>) => res.data)
    .catch((error: AxiosError) => {
      throw new Error(error?.message);
    });
};
