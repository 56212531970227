import { postChangeLanguage } from '@api/POST_ChangeLanguage';
import { useAuth } from '@context/AuthContext';
import { useEntities } from '@context/EntitiesContext';
import { useI18n } from '@hooks/useI18n';
import { useNavigationConfig } from '@hooks/useNavigationConfig';
import { LanguageType } from '@interface/UserInterface';
import {
  ColorBlue,
  ColorLight,
  ColorRed,
  Navbar,
  NavbarUserDropdown,
  Sidebar,
  useClickOutside
} from '@uangcermat/uikit-web';
import { getGuardedNavigation } from '@utils/getGuardedNavigation';
import { localStorageService } from '@utils/localStorage';
import { syncRefreshToken } from '@utils/shouldRefreshToken';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import pkgJson from '../../package.json';

const initialUserData: NavbarUserDropdown = {
  name: '',
  avatarUrl: '',
  isShowAccount: false,
  isHoverable: true,
  onClickProfile: () => undefined,
  onClickLogout: () => undefined
};

interface DashboardLayoutProps {
  children: React.ReactNode;
}

const NavbarContainerStyled = styled.div`
  ul {
    top: 60px !important;
    z-index: 9;
  }

  > div {
    div[data-test-id='navbarCompany'] {
      margin-left: 16px;
    }
    > div:nth-child(2) > div:first-child {
      display: none;
    }
  }
`;

const LayoutStyled = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;
`;

const MainStyled = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
`;

const SidebarContainerStyled = styled.div`
  min-width: 208px !important;
  // TODO: move this styling to UI kit
  li > div > p {
    font-size: 14px;
    line-height: 16px;
  }
`;

const companyImgUrl = process.env.NAVBAR_LOGO_URL ?? '/images/navbar-logo.svg';
const dashboardRoute = '/dashboard/cooperative';

const appData = {
  appName: process.env.APP_LABEL ?? process.env.APP_NAME,
  appVersion: pkgJson.version
};

const isShouldHideSidebar = (pathname: string): boolean => {
  return ['/dashboard/entities'].includes(pathname);
};

export default function DashboardLayout({ children }: DashboardLayoutProps) {
  const currentLanguage = (localStorageService.getLanguage('i18nextLng') as LanguageType) ?? 'en';
  const { changeLanguage } = useI18n();
  const router = useRouter();
  const { navigationConfig } = useNavigationConfig();
  const { t } = useTranslation(['common']);
  const { logout, userAuth, setUserAuth, activeCompany } = useAuth();
  const { entities } = useEntities();
  const lastPathname = sessionStorage.getItem('lastPathname');
  const referrer = sessionStorage.getItem('referrer');

  const companyBranchSwitcherRef = useRef(null);
  const userRefElm = useRef(null);

  const [activeMenuIdSidebar, setActiveMenuIdSidebar] = useState('');
  const [isShowAccount, setIsShowAccount] = useState(false);
  const [isShowBranch, setIsShowBranch] = useState(false);

  const [isHideSidebar, setIsHideSidebar] = useState(false);

  useClickOutside({
    ref: companyBranchSwitcherRef,
    cb: () => {
      setIsShowBranch(false);
    }
  });

  useClickOutside({
    ref: userRefElm,
    cb: () => {
      setIsShowAccount(false);
    }
  });

  const handleChangeLanguage = async (to: LanguageType) => {
    await postChangeLanguage({ lang: to });
    await syncRefreshToken();
    changeLanguage(to);
    router.reload();
  };

  const [userData, setUserData] = useState<NavbarUserDropdown>({
    ...initialUserData,
    isHoverable: true,
    onClickLogout: logout
  });
  const sidebarActiveIdMapper: Record<string, string> = {
    '/dashboard/cooperative': 'profile.detail',
    '/dashboard/cooperative/profile/documents': 'profile.detail',
    '/dashboard/cooperative/profile/management': 'profile.detail',
    '/member/detail': 'member.list',
    '/member': 'member.list'
  };

  useEffect(() => {
    sessionStorage.setItem('lastPathname', router.asPath);
    if (sidebarActiveIdMapper[router.asPath]) {
      sessionStorage.setItem('referrer', router.asPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.route, router]);

  useEffect(() => {
    if (referrer && router.asPath === lastPathname) {
      setActiveMenuIdSidebar(sidebarActiveIdMapper[referrer]);
    } else {
      const currentPath = router.asPath.split('/').slice(0, 3).join('/');
      setActiveMenuIdSidebar(sidebarActiveIdMapper[currentPath]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastPathname, referrer, router.asPath]);

  useEffect(() => {
    setIsHideSidebar(isShouldHideSidebar(router.pathname));
    if (isShowBranch) {
      setIsShowBranch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  useEffect(() => {
    setUserData({
      ...userData,
      email: userAuth?.email,
      name: userAuth?.name || '',
      avatarUrl: userAuth?.profilePicture || ''
    });
    changeLanguage(currentLanguage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuth]);

  return (
    <>
      <LayoutStyled>
        <NavbarContainerStyled>
          <Navbar
            onClickLogo={() => {
              router.push(dashboardRoute);
              setActiveMenuIdSidebar(sidebarActiveIdMapper[dashboardRoute]);
            }}
            appLogoUrl={companyImgUrl}
            bgColor={process.env.BG_HEADER_BAR_COLOR ?? ColorBlue.darkBlue}
            languageSwitcher={{
              activeLanguage: currentLanguage,
              isOutsideLanguageSwitcher: true,
              hoverColor: process.env.BG_SWITCHER_HEADER_BAR_COLOR ?? ColorBlue.cornFlowerBlue,
              onClickEnglish: async () => {
                if (userAuth) {
                  setUserAuth({
                    ...userAuth,
                    lang: 'en'
                  });
                }
                await handleChangeLanguage('en');
              },
              onClickBahasa: async () => {
                if (userAuth) {
                  setUserAuth({
                    ...userAuth,
                    lang: 'id'
                  });
                }
                await handleChangeLanguage('id');
              }
            }}
            company={{
              onClickBranchSwitcher: () => setIsShowBranch((prev) => !prev),
              companyBranchRefElm: companyBranchSwitcherRef,
              isShowBranch: isShowBranch,
              onClickSwitchCompany: () => router.push('/dashboard/entities'),
              onClickBranch: () => null,
              companyName: activeCompany?.name ?? '',
              listCompany: [],
              logoUrl: activeCompany?.logoUrl || '',
              hoverColor:
                entities && entities?.length > 1
                  ? process.env.BG_SWITCHER_HEADER_BAR_COLOR ?? ColorBlue.cornFlowerBlue
                  : '',
              hoverItemColor: process.env.BG_SWITCHER_HEADER_BAR_COLOR ?? ColorLight.aliceBlue,
              isHoverable: false,
              activeColor: process.env.BG_SWITCHER_HEADER_BAR_COLOR ?? ColorLight.aliceBlue,
              switchCompanyLabel: t('common:switchEntity'),
              isClickSwitcherNoBranchEnabled: entities && entities?.length > 1
            }}
            user={{
              ...userData,
              userRefElm,
              isShowAccount,
              onClickProfile: () => {
                setIsShowAccount((prev) => !prev);
              },
              hoverColor: ColorRed.deepCarmine,
              hoverItemColor: ColorLight.whiteSmoke
            }}
            isHideCompany={isHideSidebar}
          />
        </NavbarContainerStyled>
        <MainStyled>
          {!isHideSidebar && activeMenuIdSidebar && (
            <SidebarContainerStyled>
              <Sidebar
                navigationConfig={getGuardedNavigation({
                  navConfig: navigationConfig,
                  userPermission: userAuth?.permissions ?? []
                })}
                onClickNav={(nav) => {
                  setActiveMenuIdSidebar(nav.id);
                  if (nav.navLink) {
                    router.push(nav.navLink);
                  }
                }}
                activeId={activeMenuIdSidebar}
                appName={appData.appName}
                appVersion={appData.appVersion}
              />
            </SidebarContainerStyled>
          )}
          {children}
        </MainStyled>
      </LayoutStyled>
    </>
  );
}
