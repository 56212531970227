import { AxiosResponse } from 'axios';
import { DataMeInterface } from 'interface/UserInterface';

import { axiosAuthInstance } from './axios.authConfig';

export const getUserMe = async (): Promise<APIResponse<DataMeInterface>> => {
  return axiosAuthInstance
    .get('/me')
    .then((res: AxiosResponse<APIResponse<DataMeInterface>>) => res.data)
    .catch((err) => err);
};
