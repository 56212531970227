import { EntitiesInterface } from '@interface/CooperativeInterface';
import { AxiosResponse } from 'axios';

import { axiosCooperativeServiceInstance } from './axios.cooperativeServiceConfig';

export const getEntities = async ({
  userId
}: {
  userId: string;
}): Promise<APIResponse<Array<EntitiesInterface>>> => {
  return axiosCooperativeServiceInstance
    .get(`/cooperatives/entityList/${userId}`)
    .then((res: AxiosResponse<APIResponse<Array<EntitiesInterface>>>) => res.data)
    .catch((err) => err);
};
