import { AuthProvider, ProtectRoute } from './AuthContext';
import { EntitiesProvider } from './EntitiesContext';

const AppContext = ({ children }: { children: React.ReactNode }) => (
  <AuthProvider>
    <EntitiesProvider>
      <ProtectRoute>{children}</ProtectRoute>
    </EntitiesProvider>
  </AuthProvider>
);
export default AppContext;
