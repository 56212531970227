import { getEntities } from '@api/GET_Entities';
import { EntitiesInterface } from '@interface/CooperativeInterface';
import { localStorageService } from '@utils/localStorage';
import { useRouter } from 'next/router';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { useAuth } from './AuthContext';

interface EntitiesContextInterface {
  entities: Array<EntitiesInterface> | undefined;
}

export const EntitiesContext = createContext<EntitiesContextInterface>({
  entities: []
});

export const useEntities = () => useContext(EntitiesContext);

const isRedirectedToDashboard = (pathname: string): boolean => {
  return ['/', '/login', '/404'].includes(pathname);
};

export const EntitiesProvider = ({ children }: { children: ReactNode }) => {
  const { userAuth, setActiveCompany, setIsLoading } = useAuth();
  const router = useRouter();
  const [entities, setEntities] = useState<Array<EntitiesInterface>>();

  const { refetch: refetchEntities } = useQuery<APIResponse<Array<EntitiesInterface>>>(
    ['/cooperatives/entityList', { userId: userAuth?.id }],
    () => getEntities({ userId: userAuth?.id ?? '' }),
    {
      enabled: false,
      onSuccess: ({ data, error }) => {
        if (!error) {
          const activeCompanyLocalStorage: EntitiesInterface =
            typeof localStorageService.getActiveCompany('active_company') === 'string' &&
            JSON.parse(localStorageService.getActiveCompany('active_company') || '');

          if (data.length === 1) {
            setActiveCompany(data[0]);
          } else {
            setActiveCompany(activeCompanyLocalStorage);
          }
          setEntities(data);
          setIsLoading(false);
          if (isRedirectedToDashboard(router.pathname)) {
            if (activeCompanyLocalStorage || data.length === 1) {
              router.push('/dashboard/cooperative');
            } else {
              router.push('/dashboard/entities');
            }
          }
        }
      }
    }
  );

  useEffect(() => {
    if (userAuth) {
      refetchEntities();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuth]);

  return (
    <EntitiesContext.Provider
      value={{
        entities
      }}
    >
      {children}
    </EntitiesContext.Provider>
  );
};
