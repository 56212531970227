import { getUserLogout } from '@api/GET_Logout';
import { getUserMe } from '@api/GET_Me';
import { postUserLogin } from '@api/POST_Login';
import { EntitiesInterface } from '@interface/CooperativeInterface';
import { LoadingIndicatorBox } from '@uangcermat/uikit-web';
import { appCookies } from '@utils/appCookies';
import { epochFormatter } from '@utils/epochTimeFormatter';
import { isAxiosError } from '@utils/errorUtils';
import { localStorageService } from '@utils/localStorage';
import { DataMeInterface, LanguageType, PostBodyLoginInterface } from 'interface/UserInterface';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

interface AuthContextInterface {
  isAuthenticated: boolean;
  isLoading: boolean;
  userAuth: DataMeInterface | null;
  errorMessage: string | null;
  login: (value: PostBodyLoginInterface) => void;
  logout: () => void;
  setUserAuth: (value: DataMeInterface) => void;
  setErrorMessage: (message: null | string) => void;
  activeCompany: EntitiesInterface | undefined;
  setActiveCompany: (company: EntitiesInterface) => void;
  setIsLoading: (loading: boolean) => void;
}

export const AuthContext = createContext<AuthContextInterface>({
  isAuthenticated: false,
  isLoading: true,
  userAuth: null,
  errorMessage: null,
  login: () => undefined,
  logout: () => undefined,
  setUserAuth: () => undefined,
  setErrorMessage: () => undefined,
  activeCompany: undefined,
  setActiveCompany: () => undefined,
  setIsLoading: () => undefined
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [userAuth, setUserAuth] = useState<DataMeInterface | null>(null);
  const [activeCompany, setActiveCompany] = useState<EntitiesInterface>();
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [isLoading, setIsLoading] = useState(true);

  const { setCookie, removeCookie } = appCookies();
  const router = useRouter();

  const { refetch: refetchUserData } = useQuery<APIResponse<DataMeInterface>>('/me', getUserMe, {
    enabled: false,
    onSuccess: ({ data, error }) => {
      if (!error) {
        setUserAuth(data);
      }
    }
  });

  const { data: logoutData, refetch: refetchLogout } = useQuery('/logout', getUserLogout, {
    enabled: false,
    onSuccess: () => {
      try {
        if (!logoutData?.error) {
          removeCookie({
            name: 'access_token'
          });
          removeCookie({
            name: 'refresh_token'
          });
          setUserAuth(null);
          setActiveCompany(undefined);
          localStorageService.clearActiveCompany('active_company');
          router.push('/login');
        } else {
          alert(logoutData.message);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (isAxiosError(error)) {
          alert(error?.message);
        }
      }
    }
  });

  const { mutateAsync: mutateAsyncLogin } = useMutation('/login', postUserLogin, {
    onSuccess: () => {
      setIsLoading(false);
    }
  });

  const fetchUserProfile = useCallback(async () => {
    if (!userAuth) {
      setIsLoading(true);
      await refetchUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, userAuth]);

  useEffect(() => {
    async function loadUserFromCookies() {
      const token = Cookies.get('access_token');
      const refreshToken = Cookies.get('refresh_token');
      if (token) {
        if (!userAuth) {
          fetchUserProfile();
        }
      } else {
        if (refreshToken) {
          fetchUserProfile();
        } else {
          setIsLoading(false);
          if (router.pathname !== '/login') {
            router.push('/login');
          }
        }
      }
    }
    loadUserFromCookies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = () => {
    refetchLogout();
  };

  const login = async ({
    email,
    password,
    lang
  }: {
    email: string;
    password: string;
    lang: LanguageType;
  }) => {
    try {
      setIsLoading(true);
      const { error, access_token, refresh_token, expire_token, message } = await mutateAsyncLogin({
        email,
        password,
        lang
      });
      if (!error && access_token && !userAuth) {
        setCookie({
          name: 'access_token',
          value: access_token,
          options: {
            expires: expire_token ? epochFormatter(expire_token) : undefined
          }
        });
        if (refresh_token) {
          setCookie({
            name: 'refresh_token',
            value: refresh_token
          });
        }
        localStorageService.setToken({
          key: 'expire_token',
          value: expire_token?.toString()
        });
        fetchUserProfile();
      }
      if (error && message) {
        setErrorMessage(message);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (isAxiosError(error)) {
        const errMsg = error.response?.data.message;
        setErrorMessage(errMsg?.email || errMsg?.password || error.message);
      }
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!userAuth,
        userAuth,
        login,
        isLoading,
        logout: handleLogout,
        errorMessage,
        setErrorMessage,
        setUserAuth,
        activeCompany,
        setActiveCompany,
        setIsLoading
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

// eslint-disable-next-line react/prop-types
export const ProtectRoute = ({ children }: { children: ReactNode }) => {
  const { isAuthenticated, isLoading } = useAuth();
  const router = useRouter();

  return (
    <>
      {(isLoading || (!isAuthenticated && router.pathname !== '/login')) && (
        <LoadingIndicatorBox color={process.env.LOADING_INDICATOR_COLOR} backdrop />
      )}
      {children}
    </>
  );
};
