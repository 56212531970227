import {
  EnglishCommon,
  EnglishCooperative,
  EnglishLoginPage,
  EnglishMember,
  EnglishMemberDetail,
  EnglishMenu
} from '@i18n/locales/en';
import {
  BahasaCommon,
  BahasaCooperative,
  BahasaLoginPage,
  BahasaMember,
  BahasaMemberDetail,
  BahasaMenu
} from '@i18n/locales/id';
import { LanguageType } from '@interface/UserInterface';
import { localStorageService } from '@utils/localStorage';
// eslint-disable-next-line import/no-named-as-default
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const useI18n = () => {
  const lng: LanguageType = 'en';

  const changeLanguage = async (to: LanguageType) => {
    if (i18n.isInitialized) {
      i18n.changeLanguage(to);
      localStorageService.setLanguage({ key: 'i18nextLng', value: to });
    }
  };

  const englishResources = {
    common: EnglishCommon,
    login: EnglishLoginPage,
    menu: EnglishMenu,
    cooperative: EnglishCooperative,
    member: EnglishMember,
    memberDetail: EnglishMemberDetail
  };

  const bahasaResources = {
    common: BahasaCommon,
    login: BahasaLoginPage,
    menu: BahasaMenu,
    cooperative: BahasaCooperative,
    member: BahasaMember,
    memberDetail: BahasaMemberDetail
  };

  const resources = {
    en: englishResources,
    id: bahasaResources
  };

  const config = {
    resources,
    lng,
    ns: ['common', 'login', 'menu', 'cooperative', 'member', 'memberDetail']
  };

  const init = () => {
    i18n.use(initReactI18next).init(config);
  };

  return { i18n, init, changeLanguage };
};
