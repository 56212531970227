import { NavigationMenuInterface } from '@uangcermat/uikit-web';
import { useTranslation } from 'react-i18next';

export const useNavigationConfig = () => {
  const { t } = useTranslation(['menu']);
  const navigationConfig: Array<NavigationMenuInterface> = [
    {
      id: 'profile',
      icon: 'notebook',
      title: t('menu:cooperative.cooperative'),
      children: [
        {
          id: 'profile.detail',
          title: t('menu:cooperative.profile'),
          navLink: '/dashboard/cooperative'
        }
      ]
    },
    {
      id: 'member',
      icon: 'client',
      title: t('menu:member.member'),
      children: [
        {
          id: 'member.list',
          title: t('menu:member.memberList'),
          navLink: '/member'
        }
      ]
    }
  ];

  return { navigationConfig };
};
